import React, { Component } from "react";
import { Container, Row, Col, Modal, ModalBody } from "reactstrap";
import { navigate } from "gatsby";
import { withCookies } from "react-cookie";

// import images
import geoGateImg from "../../assets/images/geo-gate-map.png";
import galloLogo from "../../assets/images/gallosalame-logo.png";
import galileoLogo from "../../assets/images/galileo-logo.png";

class RegionPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal:
        props.cookies.get("GALLO_SITE") === "active" ||
        (typeof window !== "undefined" &&
          sessionStorage.getItem("openModal") === "active")
          ? false
          : true,
    };
  }
  toggleModal = () => {
    const { openModal } = this.state;
    this.setState({ openModal: !openModal });
  };
  handleClick = (ev) => {
    const userTouchPoint = Math.round((ev.clientX * 100) / window.innerWidth);

    if (userTouchPoint <= 47) {
      navigate("/");
      this.setState({ openModal: false });
      typeof window !== "undefined" &&
        sessionStorage.setItem("openModal", "active");
    } else {
      navigate(process.env.GATSBY_GALILEO_URL);
    }
  };
  render() {
    console.log(process.env.GATSBY_GALILEO_URL);
    const { openModal } = this.state;
    return (
      <section>
        <Modal isOpen={openModal} toggle={this.toggleModal}>
          <Row>
            <Col md="12" xs="12" className="p-0 text-center">
              <img
                className="gagl-geogate-logo-img"
                src={galloLogo}
                alt="Gallo Logo"
              />
              <img
                className="gagl-geogate-logo-img"
                src={galileoLogo}
                alt="Galileo Logo"
              />
            </Col>
          </Row>
          <ModalBody>
            <section className="ga-geogate-wrapper">
              <Container className="p-0">
                <Row>
                  <Col className="ga-geogate-header-container">
                    <h1 className="ga-geogate-header-text">
                      SELECT YOUR REGION FOR THE BEST SITE EXPERIENCE
                    </h1>
                    <div>
                      <img
                        onClick={this.handleClick}
                        className="ga-geogate-map"
                        src={geoGateImg}
                        alt="Geo Gate Map"
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </ModalBody>
        </Modal>
      </section>
    );
  }
}

export default withCookies(RegionPopup);
